<template>
  <measure-detail
    :id="measureId"
    @loaded="setTitle"
  />
</template>

<script>
import MeasureDetail from '@/components/clinician/measures/MeasureDetail'
import { mapGetters } from 'vuex'
import SetPageTitle from '@/mixins/SetPageTitle'
import { Helpers } from '@/mixins/Helpers'

export default {
  name: 'ClinicianPatientMeasureDetail',
  components: {
    MeasureDetail
  },
  mixins: [Helpers],
  computed: {
    measureId () {
      return this.$getDecodedId(this.$route.params.libraryId)
    },
    ...mapGetters({
      details: 'measureLibraryDetail'
    })
  },
  methods: {
    setTitle () {
      SetPageTitle(this.$route.name, { ShortName: this.details.measure_storage.abbreviation, ID: this.details.measure_id })
    }
  }
}
</script>
